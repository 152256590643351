export const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 17.75 19"
    height="24"
    width="24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99995 4.47999C10.6899 4.47999 11.8299 5.20999 12.4799 5.81999L15.0199 3.34C13.4599 1.89 11.4299 1 8.99995 1C5.47995 1 2.43996 3.02 0.959961 5.95999L3.86996 8.21999C4.59995 6.04999 6.61995 4.47999 8.99995 4.47999Z"
      fill="#EA4335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.64 10.2003C17.64 9.46035 17.58 8.92035 17.45 8.36035H9V11.7003H13.96C13.86 12.5303 13.32 13.7803 12.12 14.6203L14.96 16.8203C16.66 15.2503 17.64 12.9403 17.64 10.2003Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.87999 11.78C3.68335 11.207 3.58201 10.6057 3.57999 9.99995C3.57999 9.37996 3.68999 8.77996 3.86999 8.21996L0.959998 5.95996C0.329738 7.21349 0.00100539 8.5969 0 9.99995C0 11.45 0.349999 12.8199 0.959998 14.0399L3.87999 11.78Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 18.9998C11.43 18.9998 13.47 18.1998 14.9599 16.8198L12.12 14.6198C11.36 15.1498 10.34 15.5198 8.99996 15.5198C6.61996 15.5198 4.59996 13.9498 3.87997 11.7798L0.969971 14.0398C2.44997 16.9798 5.47996 18.9998 8.99996 18.9998Z"
      fill="#34A853"
    />
  </svg>
);
