
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iLCJZb3VyIGpvYiBtYXRjaGVzIGFyZSB1cGRhdGVkIjoiWW91ciBqb2IgbWF0Y2hlcyBhcmUgdXBkYXRlZCIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIkdyZWF0ISBGaW5kaW5nIHRob3NlIHJvbGVzIGZvciB5b3UiOiJHcmVhdCEgRmluZGluZyB0aG9zZSByb2xlcyBmb3IgeW91IiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmtzIGZvciBzaGFyaW5nIjoiVGhhbmtzIGZvciBzaGFyaW5nIiwiV29ya2luZyBvbiB0aGUgcmlnaHQgbWF0Y2hlcyI6Ildvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiLCJUaGF04oCZcyBoZWxwZnVsIjoiVGhhdOKAmXMgaGVscGZ1bCIsIkxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyI6Ikxvb2tpbmcgYXQgeW91ciBqb2IgbWF0Y2hlcyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJbxbTEg8SDxIPguIHguLXguYnhua8gw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaE%2FXSIsIllvdXIgam9iIG1hdGNoZXMgYXJlIHVwZGF0ZWQiOiJbw53DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaEgxIPEg8SDxZnhur3hur3hur0gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMXSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiW%2BG4iuG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZ4bq94bq9xpLhur3hur3FmeG6veG6veC4geC4teC5icOn4bq94bq9xaFdIiwiQmV0YSI6Ilvhup7hur3hur3hua%2FEg8SDXSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IlvFgeG6veG6veG6veG5ryBtzILhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur0gw6zDrMOsxpIgw48n4bm94bq94bq94bq9IMSjw7bDtsO24bmvIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua9dIiwiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSI6IlvHpsWZ4bq94bq94bq9xIPEg8SD4bmvISDGkcOsw6zDrOC4geC4teC5icaMw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw7bDtsO2xaHhur3hur3hur0gxZnDtsO2w7bGmuG6veG6veG6vcWhIMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaXSIsIkdvb2QgdG8ga25vdyI6IlvHpsO2w7bDtsO2w7bDtsaMIOG5r8O2w7bDtiDEt%2BC4geC4teC5icO2w7bDtsW1XSIsIlRoYW5rcyBmb3Igc2hhcmluZyI6Ilvhua7huKnEg8SDxIPguIHguLXguYnEt8WhIMaSw7bDtsO2xZkgxaHhuKnEg8SDxIPFmcOsw6zDrOC4geC4teC5icSjXSIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcOsw6zDrMSj4bip4bmvIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIiwiVGhhdOKAmXMgaGVscGZ1bCI6Ilvhua7huKnEg8SDxIPhua%2FigJnFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJbxYHDtsO2w7bDtsO2w7bEt8Osw6zDrOC4geC4teC5icSjIMSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXYW50IGJldHRlciBqb2IgbWF0Y2hlcz8iOiJbxbTEg8SDxIPguIHguLXguYnhua8gw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaE%2FXSIsIllvdXIgam9iIG1hdGNoZXMgYXJlIHVwZGF0ZWQiOiJbw53DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaEgxIPEg8SDxZnhur3hur3hur0gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMXSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiW%2BG4iuG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZ4bq94bq9xpLhur3hur3FmeG6veG6veC4geC4teC5icOn4bq94bq9xaFdIiwiQmV0YSI6Ilvhup7hur3hur3hua%2FEg8SDXSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IlvFgeG6veG6veG6veG5ryBtzILhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur0gw6zDrMOsxpIgw48n4bm94bq94bq94bq9IMSjw7bDtsO24bmvIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua9dIiwiR3JlYXQhIEZpbmRpbmcgdGhvc2Ugcm9sZXMgZm9yIHlvdSI6IlvHpsWZ4bq94bq94bq9xIPEg8SD4bmvISDGkcOsw6zDrOC4geC4teC5icaMw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw7bDtsO2xaHhur3hur3hur0gxZnDtsO2w7bGmuG6veG6veG6vcWhIMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaXSIsIkdvb2QgdG8ga25vdyI6IlvHpsO2w7bDtsO2w7bDtsaMIOG5r8O2w7bDtiDEt%2BC4geC4teC5icO2w7bDtsW1XSIsIlRoYW5rcyBmb3Igc2hhcmluZyI6Ilvhua7huKnEg8SDxIPguIHguLXguYnEt8WhIMaSw7bDtsO2xZkgxaHhuKnEg8SDxIPFmcOsw6zDrOC4geC4teC5icSjXSIsIldvcmtpbmcgb24gdGhlIHJpZ2h0IG1hdGNoZXMiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcOsw6zDrMSj4bip4bmvIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIiwiVGhhdOKAmXMgaGVscGZ1bCI6Ilvhua7huKnEg8SDxIPhua%2FigJnFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJMb29raW5nIGF0IHlvdXIgam9iIG1hdGNoZXMiOiJbxYHDtsO2w7bDtsO2w7bEt8Osw6zDrOC4geC4teC5icSjIMSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    