import type { Zone } from '@seek/audience-zones';
import { useMelwaysInfo } from '@seek/melways-react';
import type { Country, Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { stringify } from 'query-string';
import { useMemo } from 'react';

import { useZoneFeatures } from 'src/config/appConfig';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { useSelector } from 'src/store/react';
import { selectUserAgent } from 'src/store/selectors';
import { useMelwaysCountry, useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';
import type { BannerProps } from '../HomePageBanner';

import candidateImage from './GenericAsiaCandidateBanner.png';
import hirerImage from './GenericAsiaHirerBanner.png';
import jobstreetJuicySalaryNews from './Jobstreet_JuicySalaryNews.png';
import SGMarketingBannerImage from './SGMarketingBanner.png';

const useGenericAsiaMarketingContent = (): [BannerProps, BannerProps] => {
  const { t } = useTranslations(translations);

  const country = useMelwaysCountry();
  const zone = useMelwaysZone();
  const { brand, language } = useMelwaysInfo();
  const {
    HOMEPAGE_LHS_BANNER_TYPE: lhsBannerType,
    HOMEPAGE_RHS_BANNER_TYPE: rhsBannerType,
  } = useZoneFeatures();
  const userAgent = useSelector(selectUserAgent);

  const lhsBanner: BannerProps = useMemo(() => {
    // SG marketing campaign banner (We Got You)
    // double verification - SG country and 'SG_MARKETING_BANNER_WE_GOT_YOU' zone config
    if (
      country === 'sg' &&
      lhsBannerType === 'SG_MARKETING_BANNER_WE_GOT_YOU'
    ) {
      return {
        text: {
          title: t('Looking for the right role?'),
          subTitle: {
            label: t(
              `We've curated companies, opportunities, and advice tailored for you.`,
            ),
          },
        },
        background: {
          color: '#d9accd',
          type: 'full',
        },
        cta: {
          label: t('Explore now'),
          variant: 'solid',
          tone: 'neutral',
          inverted: true,
          linkContext: getLinkContext('sgWeGotYou'),
        },
        location: getCTALocation({
          zone,
          language,
          country,
          bannerType: 'sgWeGotYou',
          userAgent,
        }),
      };
    }

    return {
      text: {
        title:
          brand === 'jobstreet'
            ? t('All the juicy salary news in your industry')
            : t('"Hello" to a better career and salary'),
        inverted: true,
      },
      background: {
        color: '#E60278',
        imageUrl:
          brand === 'jobstreet' ? jobstreetJuicySalaryNews : candidateImage,
        centerImage: brand === 'jobstreet',
      },
      cta: {
        label:
          brand === 'jobstreet'
            ? t('Go to Community')
            : t('Get tips and tools'),
        variant: 'solid',
        tone: 'neutral',
        inverted: true,
        linkContext: getLinkContext('candidate'),
      },
      location: getCTALocation({
        zone,
        language,
        country,
        bannerType: 'candidate',
        userAgent,
      }),
    };
  }, [brand, country, language, lhsBannerType, t, userAgent, zone]);

  const rhsBanner: BannerProps = useMemo(() => {
    // SG marketing campaign banner (SEEK Pass)
    // double verification - SG country and 'SG_MARKETING_BANNER_SEEK_PASS' zone config
    if (country === 'sg' && rhsBannerType === 'SG_MARKETING_BANNER_SEEK_PASS') {
      return {
        text: {
          title: t('Verify your work credentials securely with SEEK Pass.'),
          subTitle: {
            label: t(
              'Now integrated with Singapore Careers & Skills Passport.',
            ),
          },
        },
        background: {
          color: '#E6EAF2',
          imageUrl: SGMarketingBannerImage,
          centerImage: true,
        },
        cta: {
          label: t('Learn more'),
          variant: 'solid',
          tone: 'brandAccent',
          linkContext: getLinkContext('sgSeekPass'),
        },
        // hard code the `zone` to `anz-1` as this is the requirement for this banner
        location: getCTALocation({
          zone: 'anz-1',
          language,
          country,
          bannerType: 'sgSeekPass',
          userAgent,
        }),
      };
    }

    // Hirer
    return {
      text: {
        title: t('"Hello" to better talent matches'),
      },
      background: {
        color: '#E6EAF2',
        imageUrl: hirerImage,
      },
      cta: {
        label: t('Hire for FREE'),
        variant: 'solid',
        tone: 'brandAccent',
        linkContext: getLinkContext('hirer'),
      },
      location: getCTALocation({
        zone,
        language,
        country,
        bannerType: 'hirer',
        userAgent,
      }),
    };
  }, [country, language, rhsBannerType, t, userAgent, zone]);

  return [lhsBanner, rhsBanner];
};

const candidateCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  hk: {
    en: 'https://hk.jobsdb.com/career-advice/',
  },
  th: {
    en: 'https://th.jobsdb.com/career-advice/',
    th: 'https://th.jobsdb.com/th/career-advice/',
  },
  id: {
    en: 'https://id.jobstreet.com/community',
    id: 'https://id.jobstreet.com/id/community',
  },
  my: {
    en: 'https://my.jobstreet.com/community',
  },
  ph: {
    en: 'https://ph.jobstreet.com/community',
  },
  sg: {
    en: 'https://sg.jobstreet.com/community',
  },
};

const hirerCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  hk: {
    en: 'https://hk.employer.seek.com/',
  },
  th: {
    en: 'https://th.employer.seek.com/',
    th: 'https://th.employer.seek.com/th/',
  },
  id: {
    en: 'https://id.employer.seek.com/',
    id: 'https://id.employer.seek.com/id/',
  },
  my: {
    en: 'https://my.employer.seek.com/',
  },
  ph: {
    en: 'https://ph.employer.seek.com/',
  },
  sg: {
    en: 'https://sg.employer.seek.com/',
  },
};

const sgWeGotYouCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  sg: {
    en: 'https://sg.jobstreet.com/page/we-got-you',
  },
};

const sgSeekPassCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  sg: {
    en: 'https://sg.jobstreet.com/page/seek-pass',
  },
};

const getCTALocation = ({
  bannerType,
  country,
  language,
  zone,
  userAgent,
}: {
  zone: Zone;
  language: Language;
  country: Country;
  bannerType: string;
  userAgent: string;
}): string | undefined => {
  if (!zone || !language) {
    return undefined;
  }

  const utm_campaign = country;
  const utm_medium = isMobileUserAgent(userAgent) ? 'mobile_web' : 'desktop';
  const utm_source = 'seek';

  let href;
  let queryParams;
  switch (bannerType) {
    case 'candidate':
      href = candidateCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: `CTA-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_1',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;

    case 'hirer':
      href = hirerCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: `ILC-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_2',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;

    case 'sgWeGotYou':
      href = sgWeGotYouCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: 'MP-sg-MP-h2bb',
        utm_content: 'mainlp',
        utm_source: 'mainpage',
        utm_campaign: 'sg-c-h2bb',
        utm_medium: 'website',
      });

      break;

    case 'sgSeekPass':
      href = sgSeekPassCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: `CTA-seekpasslanding-${zone}-profile`,
      });

      break;
  }

  return href ? `${href}?${queryParams}` : undefined;
};

const getLinkContext = (bannerType: string) => {
  switch (bannerType) {
    case 'candidate':
      return {
        linkAction: 'open career advice',
        linkText: 'Get tips and tools',
      };
    case 'hirer':
      return {
        linkAction: 'open hirer site',
        linkText: 'Hire for FREE',
      };
    case 'sgWeGotYou':
      return {
        linkAction: 'open we got you',
        linkText: 'Explore now',
      };
    case 'sgSeekPass':
      return {
        linkAction: 'open seek pass',
        linkText: 'Learn more',
      };
    default:
      return {
        linkAction: '',
        linkText: '',
      };
  }
};

export default useGenericAsiaMarketingContent;
