import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2V246bMBCG7/MU1korBWlJzCGAyU3fpDJmAm7ARsZs0q323Su8SQsOJNke1F6sxAV4/sGe7x/bq8+exMe2eNmhbwuEdhUc3ZwrYJpLkaJnqpaue9bEznbxulidPwuT0tA856JwtWxsfeJsB4JMai1rW0NGmgp22lbQkULxoryQZOOFlWZhJUxJmflbzYU7Hc9N/MBzXdohMKGMsn2hZCdyl9e0AFvl2aqWv0CKmHwGZUUUNEB1ioQ8vVrxRrZ8yodgXC435Q7ymKykspPwOOmLlXRLnmawkwpMFpNCg9ApekAPxpsf66RZK6tOQz/65iXuX01v4AFYD+PH7cCj8/ctuP7fgPvWmMh0lmkO2u6npw/HUPa3NsBmrK9m9YQ+V6qzfK2N/FByDW7bUAZ9MQdFm5FKGNVgk/Y4qq4W29EwS1GAcXMcj0KK/Ci0R4m1LuyM48mNOL0Rz67XLe1+PpssNOVixkb3a4oYCN33weviUw05p6hlCkAgKnK0rOnRPXXfpgfhmFksjLMg51DOwbyGM3BsxTTQ20ivQX1TvM7A4OIMw/NI1NOwIXkeIXdQ8lPUqWq5bjXVnK0ZdVugipVux9el7FotxdrzWJRsPLbZ5QmJg9gPE7xqRHFa4fD/w2NmOE2Yok60oO+rKQ7xTEmbP1QSyWMS5xBs4ijJ/TgMEua/v6SKC6DKLRTNOQi91PJ0GD0hrahoG6pAaIQfn5AqMrrET+j0rHzioOAy4K2wgyL8eKf5YUimQMXB/2X9P+L0E0gYJh+NM7HDPo7QHgYh/nth3HEu+/feUkoepviSYJJvEEaX47+9la+ZFd0061Jhm3WpsM2Kfv02+Q5gSwYCBQ0AAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzi';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundColourImg = '_1o0xsgzj';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundImg = 'var(--_1o0xsgz2)';
export var backgroundPosition = 'var(--_1o0xsgz3)';
export var centerImage = '_1o0xsgzo';
export var containerDirection = 'var(--_1o0xsgz7)';
export var containerLayout = '_1o0xsgzf';
export var contentGutter = '_1o0xsgzg';
export var contentPaddingBottom = 'var(--_1o0xsgz9)';
export var contentPaddingLeft = 'var(--_1o0xsgza)';
export var contentPaddingRight = 'var(--_1o0xsgzb)';
export var contentPaddingTop = 'var(--_1o0xsgz8)';
export var image = '_1o0xsgzh';
export var imageHeightVar = 'var(--_1o0xsgzc)';
export var imageMinHeightVar = 'var(--_1o0xsgzd)';
export var imageWidthVar = 'var(--_1o0xsgze)';
export var maskImage = 'var(--_1o0xsgz4)';
export var nowrap = '_1o0xsgzm';
export var responsive = '_1o0xsgzn';
export var subTitle = '_1o0xsgzl';
export var title = '_1o0xsgzk';
export var titleFontSize = 'var(--_1o0xsgz6)';
export var titleTopPadding = 'var(--_1o0xsgz5)';